import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

export const OAUTH_CONFIG: AuthConfig = {
    issuer: `${environment.sso_url}`,
    redirectUri: `${environment.sso_redirect_uri}`,
    clientId: `${environment.sso_client_id}`,
    responseType: 'code',
    showDebugInformation: false,
    scope: 'openid',
    requireHttps: false,
    customQueryParams: { kc_idp_hint: 'govbr' }
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userProfile: any;
    private timeAuth = 'auth_time';
    private flagRefleshToken: boolean = false;
    constructor(private oauthService: OAuthService) {}

    login(): void {
        this.oauthService.configure(OAUTH_CONFIG);
        this.oauthService.setStorage(localStorage);
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then((r) => {

            this.oauthService.tryLogin().then(() => {
                if (!this.oauthService.hasValidAccessToken()) {
                    this.oauthService.initLoginFlow();
                } else {
                    this.oauthService.setupAutomaticSilentRefresh();

                    this.oauthService.loadUserProfile().then(up => {
                      this.userProfile = up;
                      if(this.flagRefleshToken){
                        this.storeRefreshExpiresIn(this.userProfile);
                      } else {
                        this.storeRefreshExpiresIn();
                      }

                      localStorage.setItem("user_data", JSON.stringify(up));
                    }).catch(reason => this.onError(reason, "LoadUserProfile failed"));
                }
            }).catch(reason => this.onError(reason, "TryLogin failed"));
        }).catch(reason => this.onError(reason, "LoadDiscoveryDocumentAndTryLogin failed"));

    }

    logout() {
        this.oauthService.logOut();
        localStorage.clear();
    }

    private onError(reason: any, msg: string): void {
        console.log(msg);
        console.log(reason);
        localStorage.clear();
        this.oauthService.initLoginFlow();
    }

    storeRefreshExpiresIn(profile?:any) {
      let auth_time;
      let diferenca:boolean;
      let currentTimestamp;
      let targetTimestamp;

      if(profile  && profile.auth_time){
        auth_time = profile.auth_time ;
        diferenca = true;
      }else {
        auth_time = Date.now();
        diferenca = false;
      }
      const tokenResponse = this.oauthService.getRefreshToken();
      if (tokenResponse) {
        const parsedToken = this.parseJwt(tokenResponse);
        if (parsedToken && parsedToken.exp) {

          if(diferenca){
             currentTimestamp = auth_time;
             targetTimestamp = parsedToken.exp - 1;
          } else{
             currentTimestamp =  Math.floor(Date.now() / 1000);
             targetTimestamp = parsedToken.exp + 1;
          }

          const secondsRemaining = targetTimestamp - currentTimestamp;
          localStorage.setItem('refresh_expires_in', secondsRemaining.toString());
        }
      }
    }

    parseJwt(token: string) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
}
