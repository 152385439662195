import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/service/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    
    constructor(private authService: AuthService) {
        this.authService.login();
    }
}
