import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from './service/auth.service';


@NgModule({
    imports: []
})
export class AppCoreModule {
    static forRoot(): ModuleWithProviders<AppCoreModule> {
        return {
            ngModule: AppCoreModule,
            providers: [
                AuthService
            ]
        };
    }
}
