import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppCoreModule } from './core/app-core.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppCoreModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['*'],
                sendAccessToken: true,
            },
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
